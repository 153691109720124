<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('roles.rolesList') }}</span>
        <v-btn
          v-if="checkAccessRight('company-c-eate')"
          :class="locale === 'he' ? 'float-left' : 'float-right'"
          outlined
          small
          color="primary"
          :to="{path: 'role/addRole'}"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('roles.role') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <ListSkeleton v-if="listSkeleton"/>
          <v-card-text class="text-h5 pa-0" v-else-if="rolesList.data">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="rolesList.data"
              :items-per-page="limit"
              :page.sync="page"
              :server-items-length="rolesList.meta.total"
              :options.sync="pagination"
              item-class="py-4"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :search="search"
              @dblclick:row="redirectOnEditPage"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
              @update:sort-by="(val) => buildUrl(val, 'sortKey')"
              @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
            >
              <template
                v-slot:top
              >
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="4" class="text-end d-flex">
                      <v-text-field
                        :dense="true"
                        :full-width="false"
                        hide-details="auto"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
              <template v-slot:[`item.name_he`]="props">
                {{props.item.name_he ? props.item.name_he : props.item.name}}
              </template>
              <template v-slot:[`item.actions`]="props">
                <v-menu
                  v-if="(checkAccessRight('company-edit') && props.item.slug !== 'admin') || (checkAccessRight('company-d-lete') && props.item.slug !== 'admin')"
                  offset-x
                  left
                  content-class="elevation-2"
                  flat
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-if="checkAccessRight('company-edit') && props.item.slug !== 'admin'" dense class="primary--text" :to="'role/editRole/' + props.item.id">
                      <v-list-item-content>
                        <v-list-item-title>{{$t('common.edit')}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="primary--text">mdi-circle-edit-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item v-if="checkAccessRight('company-d-lete') && props.item.slug !== 'admin'" dense @click="confirmationDialog = true, delete_item = props.item">
                      <v-list-item-content class="error--text">
                        <v-list-item-title>{{$t('common.delete')}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="error--text">mdi-delete-sweep-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{rolesList.meta.total}}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        @input="buildUrl"
                        :length="rolesList.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
        </v-card>
      </v-col>
      <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import ConfirmationDialog from '@/components/ConfirmationDialog';

export default {
  name: 'Roles',
  components: {
    ListSkeleton,
    ConfirmationDialog
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      listSkeleton: false,
      limit: 10,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      accessRights: [],
      confirmationDialog: false,
      delete_item: null,
      search: this.$route.query.search || "",
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || 'name'],
        sortDesc: this.$route.query.order_by ? this.$route.query.sortDec ? [true] : [false] : this.$route.query.sortDec ? [true] : [false]
      },
    }
  },
  async mounted() {
    this.listSkeleton = true;
    this.$store.commit("SET_ROLE_QUERY", this.$route.query);
    if(Object.keys(this.$route.query).length){
      this.paginate(false);
    } else {
      this.pagination.page = 1
      this.pagination.itemsPerPage = 10
      this.pagination.sortBy = ['name']
      this.pagination.sortDesc = [false]
    }
    if(this.user.role) {
      this.accessRights = this.user.role.permissions;
    }
  },
  watch: {
    params: {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.paginate();
      },
      deep: true
    },
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.page = Number(this.$route.query.page) || 1
          
          this.sortByValue = this.$route.query.order_by || null

          this.sortDescValue = this.$route.query.sortDec ? true : null

          // this.pagination = {
          //   page: Number(this.$route.query.page) || 1,
          //   itemsPerPage: 10,
          //   sortBy: [this.$route.query.order_by || 'name'],
          //   sortDesc: this.$route.query.order_by ? this.$route.query.sortDec ? [true] : [false] : [false],
          // }
          
          this.search = this.$route.query.search || ""
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
      };
    },
    ...mapGetters([
      'rolesList',
      'locale',
      'user'
    ]),
    headers() {
      return [
        {
          text: this.$t('roles.id'),
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: this.$t('roles.roleName'), value: this.locale !== 'he' ? "name" : "name_he" },
        { text: this.$t('roles.action'), value: "actions", align: "center", sortable: false },
      ]
    },
  },
  methods: {
    async paginate() {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage
      } = this.params;
      await this.$store.dispatch('GetRoles', {page: page, limit: itemsPerPage, where_like: this.search ? sortBy[0] ? sortBy[0]+'|'+this.search : sortBy[1] ? sortBy[1]+'|'+this.search : 'id,name|'+ this.search : 'id,name|', order_by: sortBy?.[0] ? sortBy?.[0]+',id|'+(sortDesc?.[0] ? 'desc' : 'asc')+',desc' : 'name,id|'+(sortDesc?.[0] ? 'desc' : 'asc')+',desc'}).then(()=>this.listSkeleton = false);
    },
     buildUrl(val, url_key) {
      let obj = {};
      if (typeof val === 'string' && url_key === 'sortKey' && !this.$route.query.page) {
        return
      }
      if (typeof val === 'string') {
        this.page = 1;
        obj.page = 1;
        this.params.page = 1;
      }
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj.search = this.search;
      obj.page = this.page;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "groupId" && val === null) return acc;
        else if (key === "page" && val === 1) return acc;
        else if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router.push({
        name: "Roles",
        query: {
          ...obj,
        },
      }).catch(()=>{});
      this.$store.commit("SET_ROLE_QUERY", obj);
    },
    redirectOnEditPage(event, {item}) {
      this.checkAccessRight('company-edit') && item.slug !== 'admin' ? this.$router.push('role/editRole/' + item.id) : ''
    },
    checkAccessRight(checkName) {
      // console.log(this.accessRights);
      // this.accessRights.forEach((element) => {
      //   console.log(element);
      // })
      // console.log(this.accessRights.some((role) => role.name === checkName));
      if(this.accessRights.length > 0) {
        return this.accessRights.some((role) => role.slug === checkName);
      } else {
        return true;
      } 
    },
    async deleteItem(item) {
      let permission_ids = [];
      item.permissions.forEach(element => {
        permission_ids.push(element.id);
      })
      await this.$store.dispatch('DeleteItemById', {...item, permission_ids: permission_ids});
      this.search = this.$route.query.search || "";
      this.page = Number(this.$route.query.page) || 1;
      this.limit = 10;
      this.paginate();
    }
  }
}
</script>
